@import url('https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;1,400&display=swap');

@font-face {
  font-family: 'Droid Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./images/DroidSans.ttf');
  src: local('Droid Sans'), url('./images/DroidSans.ttf') format('truetype');
}

@font-face {
  font-family: 'CutiveMono';
  font-style: normal;
  font-weight: 400;
  src: url('./images/CutiveMono-Regular.ttf');
  src: local('CutiveMono'), url('./images/CutiveMono-Regular.ttf') format('truetype');
}

.title {
  font-family: "CutiveMono";
  font-size: 16px;
}

.logo {
  width: 50px;
  height: 50px;
}

h3 {
  font-size: 22px;
  font-family: "Playfair Display";
}

li {
  padding: 5px;
  font-size: 20px;
}
ul {
  margin-bottom: 50px;
}

button:hover {
  cursor: pointer;
}

.normalLink {
  border-bottom: 2px solid transparent;
  transition-duration: 0.3s;
  padding-bottom: 3px;
}
.normalLink:hover {
  border-bottom: 2px solid;
}

button {
  padding: 0;
	border: none;
	background-color: transparent;
  transition-duration: 0.5s;
}
.rhs {
  width: 25px;
  height: 25px;
}

.cursor {
	position: relative;
}
.cursor	i {
	position: absolute;
	width: 2px;
	height: 100%;
  left: 5px;
	animation-name: blink;
	animation-duration: 750ms;
	animation-iteration-count: infinite;
	opacity: 1;
}

@keyframes blink {
	from { opacity: 1; }
	to { opacity: 0; }
}

.pageLink {
  display: table;
  font-size: 20px;
  font-family: "Playfair Display";
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 17px;
  padding-bottom: 3px;
  border-bottom: 2px solid;
  transition-duration: 0.3s;
}
.pageLink:hover {
  border-bottom: 2px solid transparent;
}

.social {
  height: 30px;
  width: 30px;
  transition-duration: 0.3s;
	color: dimgray;
}

#resume:hover {
  color: #ec9706;
}
#linkedin:hover {
	color: #0e76a8;
}
#instagram:hover {
  color: #C13584;
}
#twitch:hover {
  color: #6441a5;
}

.photo {
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.wave {
  width: 20px;
  height: 20px;
}

@media screen and (min-width: 768px) {
  .logo {
    width: 70px;
    height: 70px;
  }

  .rhs {
    width: 30px;
    height: 30px;
  }

  .social {
    height: 35px;
    width: 35px;
  }

  .photo {
    max-width: 500px;
    max-height: 500px;
    margin-bottom: 0px;
  }
}